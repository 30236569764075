import { css } from 'styled-components'

import { color, font } from '~/styles/utils'

const base = css`
  /**
  * Shared styles used by most pages.
  */

  html {
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
  }

  html,
  body,
  #root {
    margin: 0;
  }

  #root {
    isolation: isolate;
  }

  .grecaptcha-badge {
    visibility: hidden;
  }

  .amp-base {
    ${font('base')};
    font-size: 16px;
    color: ${({ theme }) => theme.color.text.onDark};
  }

  h1,
  h2,
  h3 {
    ${font('heading')};
    text-transform: uppercase;
    font-style: normal;
    font-stretch: normal;
  }

  h1 {
    font-size: 18px;
    line-height: 1.33;
  }

  h2 {
    font-size: 16px;
    line-height: 1.5;
  }

  h3 {
    font-size: 16px;
    line-height: 1.19;
  }

  p {
    ${font('base')};
    font-size: 16px;
    line-height: 1.19;
    margin: 16px 0;
  }

  small {
    ${font('base')};
    font-size: 8px;
    line-height: 1.38;
  }

  input {
    ${font('base')};
  }

  button {
    ${font('heading')};
  }

  .disclaimer {
    ${font('base')};
    font-size: 11px;
    line-height: 1.09;
    *,
    .amp-button {
      font-size: inherit;
    }
  }

  .optional {
    ${font('base')};
    color: ${color('mediumGrey')};
    text-transform: capitalize;
    font-size: 12px;
    line-height: 1.17;
  }
`

export default base

import styled, { css } from 'styled-components'

import { badge } from '~/styles/mixins'
import { font } from '~/styles/utils'

export const Content = styled.span``

export const Wrapper = styled.span<{
  $onDarkBackground?: boolean
  $small?: boolean
  $solid?: boolean
}>`
  display: inline-block;

  ${Content} {
    ${({ theme, $onDarkBackground, $small, $solid }) => {
      const badgeSize = $small ? '25px' : '32px'
      const fontSize = $small ? '14px' : '16px'

      let composedStyles = css`
        ${badge(badgeSize)}
        border: 2px solid ${theme.color.border.dark};
        display: flex;
        flex-grow: 0;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;

        background-color: transparent;
        color: ${theme.color.text.default};
        font-size: ${fontSize};
        ${font('heading')};
      `

      if ($onDarkBackground) {
        composedStyles = css`
          ${composedStyles}
          background-color: ${$solid
            ? theme.color.background.default
            : 'transparent'};
          color: ${$solid ? theme.color.text.default : theme.color.text.onDark};
          border-color: ${theme.color.border.onDark};
        `
      } else if ($solid) {
        composedStyles = css`
          ${composedStyles}
          background-color: ${theme.color.background.dark};
          color: ${theme.color.text.onDark};
        `
      }

      return composedStyles
    }}
  }
`

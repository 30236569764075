import {
  UPDATE_PROFILE_DETAILS_EDIT,
  TOGGLE_FIELD_EDITABLE,
  RESET_PROFILE_DETAILS_EDIT,
} from '../actions/profile-details-edit'

import type { Action } from '../actions/profile-details-edit'
import type { ProfileDetailsEdit } from '~/types'

export const DEFAULT_STATE: ProfileDetailsEdit = {
  id: null,
  firstName: '',
  lastName: '',
  dob: '',
  email: '',
  minorConsentApproved: false,
  errors: {},
  translatedErrors: {},
  editableFields: [
    'firstName',
    'lastName',
    'dob',
    'gender',
    'minorConsentApproved',
  ],
}

function updateEditableFields(state, fieldName, editable) {
  if (fieldName === 'cloudinary') return
  const { editableFields } = state
  let newEditableFields = editableFields.slice()
  let field = {
    [fieldName]: state[fieldName],
  }

  if (!editable) {
    newEditableFields = editableFields.filter((f) => f !== fieldName)
    field = {
      [fieldName]: '',
    }
  } else if (editable && !editableFields.includes(fieldName)) {
    newEditableFields.push(fieldName)
  }

  return { ...field, editableFields: newEditableFields }
}

export default function (
  state: ProfileDetailsEdit = DEFAULT_STATE,
  action: Action,
): ProfileDetailsEdit {
  switch (action.type) {
    case UPDATE_PROFILE_DETAILS_EDIT:
      return { ...state, ...action.data }

    case TOGGLE_FIELD_EDITABLE: {
      const newState = updateEditableFields(
        state,
        action.field,
        action.editable,
      )
      return { ...state, ...newState }
    }

    case RESET_PROFILE_DETAILS_EDIT:
      return DEFAULT_STATE

    default:
      return state
  }
}

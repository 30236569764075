import useSWRImmutable from 'swr/immutable'

import { TRANSLATION_PACK_PATH } from '~/config'
import { authedFetch } from '~/utils/fetch'
import HTTPError from '~/utils/http-error'
import { reportGroupedError } from '~/utils/logger'

async function fetch(url: string, options: Record<string, any> = {}) {
  try {
    const response = await authedFetch(url, options)

    if (response.ok) {
      const { data } = await response.json()
      return data
    } else {
      const text = await response.text()
      throw new HTTPError('fetch failed', response, text)
    }
  } catch (error) {
    if (['HTTPError', 'TypeError'].includes(error.name)) {
      reportGroupedError(url, error)
    }
    throw error
  }
}

export const useTranslationPack = () =>
  useSWRImmutable(TRANSLATION_PACK_PATH, fetch)

/**
 * Exposes the fetch api in such a way that it can be mocked in the tests.
 *
 */
import Cookies from 'js-cookie'

import { addFlashMessage } from '~/actions/flash-message'
import {
  LOCALE,
  XSRF_COOKIE_NAME,
  IS_GUEST_IMPERSONATION_SESSION,
} from '~/config'
import { i18n } from '~/i18n'
import { getStoreInstance } from '~/store-instance'
import { sessionExpired } from '~/utils/session'

const CSRF_PROTECTED_METHODS = ['PUT', 'PATCH', 'POST', 'DELETE']

/**
 * authedFetch makes sure that the fetch request includes authentication
 * information. e.g Cookies
 *
 * Will redirect to /login?expired=true if response is 401 unauthorized by default.
 * Can set unauthorizedRedirectPath to null to opt-out of this behaviour.
 */

export async function authedFetch(
  url: string,
  options: Record<string, any> = {},
  unauthorizedRedirectPath: string = '/login',
) {
  options.credentials = 'same-origin'

  if (needsCSRF(options.method)) {
    options['headers'] = options.headers || {}
    options.headers['X-CSRF-Token'] = tokenCSRF()
  }

  const joiner = url.includes('?') ? '&' : '?'
  const localizedURL = LOCALE === 'en' ? url : `${url}${joiner}locale=${LOCALE}`

  const resp = await fetch(localizedURL, options)

  if (unauthorizedRedirectPath && resp.status === 401) {
    sessionExpired(unauthorizedRedirectPath)
  }

  if (resp.status === 403 && IS_GUEST_IMPERSONATION_SESSION) {
    getStoreInstance().dispatch(
      addFlashMessage(
        'error',
        i18n.t('components.flash_messages.impersonation_request_error'),
        { exclusive: true },
      ),
    )
  }

  return resp
}

export function fetchFailed(msg: string) {
  return addFlashMessage('error', msg)
}

function needsCSRF(httpMethod) {
  if (!httpMethod) return
  return CSRF_PROTECTED_METHODS.includes(httpMethod.toUpperCase())
}

function tokenCSRF() {
  return Cookies.get(XSRF_COOKIE_NAME)
}

export function tokenAuthedFetch(
  url: string,
  token: string,
  options: Record<string, any> = {},
): Promise<Response> {
  options.credentials = 'same-origin'
  options['headers'] = {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
    ...(options.headers || {}),
  }

  return fetch(url, options)
}

import { css } from 'styled-components'

export const badge = (size = '20px') => css`
  min-width: ${size};
  height: ${size};
  padding: 0 5px;
  box-sizing: border-box;

  display: inline-block;
  text-align: center;
  line-height: calc(${size} * 0.95);

  background: ${({ theme }) => theme.color.background.alert.secondary};
  color: ${({ theme }) => theme.color.text.onDark};
  border-radius: calc(${size} / 2);
`

export const textOverflowEllipses = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

import { parseDate, stringifyDate } from './date'

function padStart(num: number | null | undefined): string {
  if (num == null) {
    return ''
  }

  let result = num.toString()

  if (result.length !== 2) {
    result = '0' + result
  }

  return result
}

export function strToStrDateObj(str: string | null | undefined) {
  let { year, month, day } = parseDate(str)

  if (Number.isInteger(month)) {
    month += 1
  }

  return {
    month: padStart(month).toString(),
    day: padStart(day).toString(),
    year: (year || '').toString(),
  }
}

export function strDateObjToStr({
  month,
  day,
  year,
}: {
  month: string
  day: string
  year: string
}) {
  let monthNum: number | null | undefined

  if (month) {
    monthNum = parseInt(month, 10)
  } else {
    monthNum = null
  }

  if (monthNum != null) {
    monthNum -= 1
  }

  let yearNum: number | null | undefined

  if (year) {
    yearNum = parseInt(year, 10)
  }

  let dayNum: number | null | undefined

  if (day) {
    dayNum = parseInt(day, 10)
  }

  return stringifyDate({
    month: monthNum,
    day: dayNum,
    year: yearNum,
  })
}

export function shortDate(dateString: string) {
  const dateObject = strToStrDateObj(dateString)
  return `${dateObject.year}-${dateObject.month}-${dateObject.day}`
}

export const today = () => {
  const d = new Date()
  let month = '' + (d.getMonth() + 1)
  let day = '' + d.getDate()
  const year = d.getFullYear()

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day

  return [year, month, day].join('-')
}

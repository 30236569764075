type Extra = Record<string, any>

export type ContextError = Error & {
  context?: Extra
}

export function reportGroupedError(
  name: string,
  error: ContextError,
  additionalDetails?: Record<string, any>,
) {
  console.error(error, {
    name: name,
    context: {
      ...(additionalDetails || {}),
      ...(error.context || {}),
    },
    fingerprint: `${name} : ${error.name}`, // ensures correct grouping
  })
}

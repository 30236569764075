import { Select } from '@base-ui-components/react/select'
import classNames from 'classnames'
import React, { useState } from 'react'

import Label from '~/components/text-input-label'

import {
  Container,
  Icon,
  Item,
  Popup,
  Trigger,
  Value,
} from './select-input.styles'

import type { ReactNode } from 'react'
export type OptionObject = {
  value: string | number
  label: string | number
  disabled?: boolean
}
export type Option = string | number | OptionObject

export type Props = {
  disabled?: boolean
  onChange: (...args: Array<any>) => any
  hasError?: boolean
  label?: ReactNode
  name?: string
  value?: string | number
  defaultValue?: string | number
  placeholder?: string
  id?: string
  options: Option[]
  className?: string
  variant?: 'primary' | 'secondary'
  fullWidth?: boolean
}

const SelectInput = ({
  id,
  disabled,
  onChange,
  hasError,
  label,
  name,
  value,
  defaultValue,
  placeholder,
  options,
  className,
  variant,
  fullWidth,
}: Props) => {
  const [open, setOpen] = useState(false)
  const [selectedValue, setSelectedValue] = useState(value?.toString())

  const onValueChange = (value: string) => {
    if (value !== selectedValue) {
      onChange({ target: { name, value } })
    }
    setSelectedValue(value)
  }

  const optionObjects = options.map((option: Option) => {
    let value
    let label
    let disabled = false

    if (typeof option === 'object') {
      ;({ value, label, disabled } = option)
    } else {
      value = option
      label = option
    }

    return {
      value,
      disabled,
      label,
    }
  })

  const fieldClass = classNames('amp-select-input', className)

  const renderLabel = () => {
    if (!label) return null
    return (
      <Label className="label" disabled={disabled} hasError={hasError}>
        {label}
      </Label>
    )
  }

  const renderItem = (option: OptionObject) => (
    <Item
      key={option.value}
      value={option.value?.toString()}
      disabled={option.disabled}
      $disabled={option.disabled}
      $selected={option.value == (value || defaultValue)}
    >
      {option.label}
    </Item>
  )

  return (
    <Container className={fieldClass}>
      {renderLabel()}
      <Select.Root
        name={name}
        open={open}
        onOpenChange={setOpen}
        defaultValue={defaultValue?.toString()}
        value={value?.toString()}
        onValueChange={onValueChange}
        alignItemToTrigger={false}
      >
        <Trigger
          id={id}
          data-testid={id}
          disabled={disabled}
          aria-label={typeof label === 'string' ? label : name}
          $variant={variant}
          $fullWidth={fullWidth}
          $hasError={hasError}
        >
          <Value placeholder={placeholder} $disabled={disabled} />
          <Icon
            glyph={open ? 'chevron-up' : 'chevron-down'}
            $disabled={disabled}
          />
        </Trigger>
        <Select.Portal>
          <Select.Positioner>
            <Popup>{optionObjects.map(renderItem)}</Popup>
          </Select.Positioner>
        </Select.Portal>
      </Select.Root>
    </Container>
  )
}

export default SelectInput

import React from 'react'

import Label from '~/components/text-input-label'
import Sublabel from '~/components/text-input-sublabel'

import {
  Container,
  LabelWrapper,
  ReactInternationalPhoneInput,
} from './phone-input.styles'

import 'react-international-phone/style.css'

import type { ReactNode } from 'react'

export type Props = {
  value: string
  onChange: (value: string) => void
  onBlur?: (value: string) => void
  disabled?: boolean
  label?: ReactNode
  sublabel?: ReactNode
  hasError?: boolean
  id?: string
  className?: string
  variant?: 'primary' | 'secondary'
  fullWidth?: boolean
}

export default function PhoneInput({
  hasError,
  disabled,
  value,
  onChange,
  onBlur,
  id = 'phone',
  label,
  sublabel,
  className,
  variant,
  fullWidth,
}: Props) {
  return (
    <Container className={className} htmlFor={id} $fullWidth={fullWidth}>
      {label && (
        <LabelWrapper>
          <Label disabled={disabled} hasError={hasError}>
            {label}
          </Label>
          {sublabel && <Sublabel>{sublabel}</Sublabel>}
        </LabelWrapper>
      )}
      <ReactInternationalPhoneInput
        $hasError={hasError}
        defaultCountry="us"
        disabled={disabled}
        $disabled={disabled}
        value={value}
        onChange={(value) => onChange(value)}
        onBlur={({ target: { value } }) =>
          onBlur ? onBlur(value) : onChange(value)
        }
        inputProps={{ id }}
        $variant={variant}
      />
    </Container>
  )
}

import classNames from 'classnames'
import React from 'react'

import Button from '~/components/button'
import ErrorMessage from '~/components/error-message'
import PasswordInput from '~/components/password-input'
import TextInputLabel from '~/components/text-input-label/text-input-label.component'
import { i18n } from '~/i18n'

import type { ReactNode } from 'react'

type CurrentPasswordProps = {
  handleCurrentPasswordChange: (
    arg0: React.SyntheticEvent<HTMLInputElement>,
  ) => void
  currentPasswordHasErrors: boolean
  errors: {
    password?: string[]
    currentPassword?: string[]
  }
}

export const CurrentPassword = ({
  handleCurrentPasswordChange,
  currentPasswordHasErrors,
  errors,
}: CurrentPasswordProps) => (
  <div className="current-password">
    <PasswordInput
      name="current-password"
      id="current-password"
      label={i18n.t('components.password.current_password_label')}
      onChange={handleCurrentPasswordChange}
      hasError={currentPasswordHasErrors}
      fullWidth
    />
    {errors.currentPassword &&
      errors.currentPassword.map((errorText, i) => (
        <ErrorMessage key={i}>{errorText}</ErrorMessage>
      ))}
  </div>
)

type NewPasswordProps = {
  displayRequirementsAbove: boolean
  passwordInputOverride: ReactNode
  handleNewPasswordChange: (
    arg0: React.SyntheticEvent<HTMLInputElement>,
  ) => void
  passwordHasErrors: boolean
  passwordErrorOverride: ReactNode
  errors: Record<string, any>
  label?: ReactNode
}

export const NewPassword = ({
  displayRequirementsAbove,
  passwordInputOverride,
  handleNewPasswordChange,
  passwordHasErrors,
  passwordErrorOverride,
  errors,
  label,
}: NewPasswordProps) => {
  const renderLabel = label || i18n.t('components.password.password_label')
  return (
    <div
      className={classNames('new-password', {
        vertical: displayRequirementsAbove,
      })}
    >
      {displayRequirementsAbove && (
        <TextInputLabel hasError={passwordHasErrors}>
          {renderLabel}
        </TextInputLabel>
      )}
      <div className="new-password-input">
        {passwordInputOverride || (
          <PasswordInput
            name="password"
            id="new-password"
            label={displayRequirementsAbove ? undefined : renderLabel}
            onChange={handleNewPasswordChange}
            hasError={passwordHasErrors}
            placeholder={i18n.t(
              'components.sign_up.fields.password.placeholder',
            )}
            fullWidth
          />
        )}
        {passwordErrorOverride ||
          errors.password.map((errorText, i) => (
            <ErrorMessage key={i}>{errorText}</ErrorMessage>
          ))}
      </div>
    </div>
  )
}

type ActionsProps = {
  onCancel?: () => void
}

export const Actions = ({ onCancel }: ActionsProps) => (
  <div className="actions">
    {onCancel && (
      <Button onClick={onCancel} type="plain">
        {i18n.t('components.form.cancel_btn')}
      </Button>
    )}
    <Button htmlType="submit" glyph="chevron-right">
      {i18n.t('pages.myaccount_settings.password_submit')}
    </Button>
  </div>
)

import { oneLineTrim } from 'common-tags'

import { i18n } from '~/i18n'

import {
  REQUIRED,
  IN_DISTANT_PAST,
  IN_FUTURE,
  INVALID_CHARACTERS,
} from './validations.constants'

import type { ErrorCode } from './validations'
import type { ReactNode } from 'react'

export type Errors = Record<string, ErrorCode>
type CustomTranslations = Record<string, Record<string, string | ReactNode>>
type RawErrors = Record<string, ErrorCode[]>

const DEFAULT_TRANSLATED_ERRORS = () => ({
  [REQUIRED]: i18n.t('components.errors.missing_field'),
  [IN_FUTURE]: i18n.t('components.errors.dob.in_future'),
  [IN_DISTANT_PAST]: i18n.t('components.errors.dob.in_distant_past'),
  [INVALID_CHARACTERS]: i18n.t('components.errors.special_characters'),
})

const DEFAULT_ATTRIBUTE_WHITELIST = ['credit_card']

function translateCode(errorCode, key, translationsByKey) {
  let msg

  if (errorCode && translationsByKey && translationsByKey[key]) {
    msg = translationsByKey[key][errorCode]
  }

  if (msg == null) {
    msg = errorCode && DEFAULT_TRANSLATED_ERRORS()[errorCode]
  }

  if (msg == null) {
    msg = i18n.t('components.errors.default')
  }

  return msg
}

export function translateErrors(
  errors?: Errors | RawErrors,
  customTranslationsByKey?: CustomTranslations,
) {
  if (!errors) {
    return {}
  }

  const result = Object.entries(errors).reduce((translated, [key, value]) => {
    if (Array.isArray(value)) {
      // Error originated in BE
      translated[key] = value[0]
    } else {
      // Error code originated in FE validations
      translated[key] = translateCode(value, key, customTranslationsByKey)
    }

    return translated
  }, {})

  return result
}

export function errorsObjectToMessage(
  errors: RawErrors,
  attributeWhitelist: string[] = DEFAULT_ATTRIBUTE_WHITELIST,
) {
  const errorAttribute = Object.keys(errors)[0]

  if (
    attributeWhitelist.every(
      (element) => errorAttribute.indexOf(element) === -1,
    )
  ) {
    return i18n.t('components.errors.default')
  }

  const errorValue = errors[errorAttribute][0]

  const errorMessage = oneLineTrim(`
    ${errorAttribute.charAt(0).toUpperCase()}
    ${errorAttribute.slice(1).replace(/_|\./g, ' ')}${' '}
    ${errorValue.toLocaleLowerCase()}.
  `)

  return errorMessage
}

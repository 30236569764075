import type { Locale } from '../types'

let LOCALE = 'en' as Locale
let AVAILABLE_LOCALES = ['en', 'fr']

export function setLocale(locale: Locale) {
  LOCALE = locale
}

export function getLocale() {
  return LOCALE
}

export function setAvailableLocales(availableLocales: Array<string>) {
  AVAILABLE_LOCALES = availableLocales
}

export function getAvailableLocales() {
  return AVAILABLE_LOCALES
}

export function pathWithoutLocale(pathname: string) {
  const locale = pathname ? pathname.split('/')[1] : ''

  if (!AVAILABLE_LOCALES.includes(locale)) return pathname

  return '/' + pathname.split('/').slice(2).join('/')
}

export function pathnameIncludesLocale(pathname: string) {
  const locales = getAvailableLocales()
  const givenLocal = pathname.split('/')[1]
  return locales.indexOf(givenLocal) >= 0
}

export function locationWithLocale(locale: Locale = getLocale()) {
  const uri = new URL(window.location.href)
  uri.pathname = `/${locale}${window.location.pathname}`
  return uri.toString()
}

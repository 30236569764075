import { Select } from '@base-ui-components/react/select'
import styled from 'styled-components'

import _Icon from '~/components/icon'
import { font } from '~/styles/utils'

export const Container = styled.label`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const Item = styled(Select.Item)<{
  $disabled?: boolean
  $selected?: boolean
}>`
  ${font('base')}
  font-size: 16px;
  text-transform: uppercase;
  box-sizing: border-box;
  padding: 0 16px;
  height: 48px;
  display: flex;
  align-items: center;
  column-gap: 16px;
  margin: 0;
  color: ${({ $disabled, theme }) =>
    $disabled ? theme.color.text.disabled : theme.color.text.default};
  background-color: ${({ theme }) => theme.color.background.input.secondary};
  border: 2px solid
    ${({ $selected, theme }) =>
      $selected ? theme.color.border.focused.default : 'transparent'};

  &:focus-visible {
    outline: none;
  }

  &:hover,
  &:active,
  &:focus {
    border: 2px solid
      ${({ $disabled, theme }) =>
        $disabled ? 'transparent' : theme.color.border.focused.default};
  }
`

export const Popup = styled(Select.Popup)`
  margin: 0;
  margin-top: 1px;
  padding: 0;
  box-shadow: 0px 16px 16px 0px rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  max-height: 200px;
  overflow-y: auto;
  cursor: default;
  width: var(--anchor-width);
`

export const Trigger = styled(Select.Trigger)<{
  $hasError?: boolean
  $fullWidth?: boolean
  $variant: string
}>`
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin: 0;
  height: 50px;
  text-align: left;
  font-size: 16px;
  font-weight: 600;
  border-radius: 0;
  text-transform: uppercase;
  padding: 0 16px;
  ${({ $fullWidth }) =>
    $fullWidth ? `width: 100%;` : `min-width: 240px; max-width: 588px;`}

  background: ${({ disabled, $variant, theme }) =>
    disabled
      ? theme.color.background.disabled
      : $variant === 'secondary'
        ? theme.color.background.input.secondary
        : theme.color.background.input.default};
  border: 1px solid
    ${({ disabled, $hasError, theme }) =>
      disabled
        ? theme.color.border.disabled
        : $hasError
          ? theme.color.border.danger
          : theme.color.border.input.default};

  &:focus-visible {
    outline: none;
  }

  &:active,
  &:focus {
    border: 2px solid ${({ theme }) => theme.color.border.focused.default};
  }
`

export const Icon = styled(_Icon)<{ $disabled?: boolean }>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
  pointer-events: none;
  color: ${({ $disabled, theme }) =>
    $disabled ? theme.color.icon.disabled : theme.color.icon.default};
`

export const Value = styled(Select.Value)<{ $disabled?: boolean }>`
  ${font('bold')}

  color: ${({ theme, $disabled }) =>
    $disabled ? theme.color.text.disabled : theme.color.text.default};
`

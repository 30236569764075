/**
 * Component to allow a custom file select button to be used instead of the
 * standard browser input. Input is hidden by css and a label is used to
 * direct the clicks etc to the input element.
 */
import classNames from 'classnames'
import React, { Component } from 'react'

import Icon from '~/components/icon'
import { ACTION_KEYCODES } from '~/config'

import './file-input.scss'

import type { ReactNode } from 'react'
import type { Glyphs } from '~/components/icon'

const MEGABYTE = 1024 * 1024

export type Props = {
  id: string
  name?: string
  accept?: string
  disabled?: boolean
  onChange?: ((file: File) => void) | null | undefined
  children?: ReactNode
  maxsize?: number
  className?: string
  icon?: Glyphs
}

type State = {
  errorMessage: string | null | undefined
}

class FileInput extends Component<Props, State> {
  input: HTMLInputElement | null | undefined

  constructor(props: Props) {
    super(props)
    this.state = {
      errorMessage: null,
    }
  }

  isValid({ size }: { size: number }) {
    const { maxsize } = this.props
    let errorMessage = null

    if (maxsize != null && size > maxsize) {
      const fmtSize = maxsize / MEGABYTE
      errorMessage = `File size too large. Maximum is ${fmtSize} MB.`
    }

    this.setState({
      errorMessage,
    })
    return !errorMessage
  }

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    if (files.length === 0 || !this.isValid(files[0])) return
    const { onChange } = this.props

    if (onChange != null) {
      onChange(e.target.files[0])
    }
  }

  handleKeyDown = (e: { keyCode: number; preventDefault: () => void }) => {
    const { input } = this

    if (ACTION_KEYCODES.includes(e.keyCode) && input != null) {
      e.preventDefault()
      input.click()
    }
  }

  renderErrorMessage() {
    const { errorMessage } = this.state
    if (!errorMessage) return
    return <div className="error-message">{errorMessage}</div>
  }

  renderIcon() {
    const { icon } = this.props
    if (!icon) return undefined
    return <Icon glyph={icon} />
  }

  render() {
    const {
      id,
      name,
      accept,
      disabled,
      children,
      className,
      onChange,
      ...htmlProps
    } = this.props
    return (
      <div className="amp-file-input-wrapper">
        {this.renderErrorMessage()}
        <label
          htmlFor={id}
          tabIndex={0}
          className={classNames('amp-file-input', className)}
          onKeyDown={this.handleKeyDown}
          {...htmlProps}
        >
          {children}
          {this.renderIcon()}
        </label>
        <input
          type="file"
          ref={(input) => {
            this.input = input
          }}
          name={name}
          id={id}
          accept={accept}
          disabled={disabled}
          onChange={this.handleChange}
        />
      </div>
    )
  }
}

export default FileInput

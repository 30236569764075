import { humanize, parameterize } from '~/utils/string'

import type { FieldDecorator, Props } from './form.types'
import type { ReactElement } from 'react'
import type { ButtonType } from '~/components/button'

export const controlName = (name: string) => parameterize(humanize(name))

export const submitBtnText = (submit: Props['submit']): string => {
  return typeof submit === 'object' ? submit.text : submit || ''
}

export const submitBtnType = (submit: Props['submit']): ButtonType => {
  return typeof submit === 'object' ? submit.type : 'progress'
}

export const wrapField = (
  decorator: FieldDecorator | null | undefined,
  element: ReactElement<unknown>,
): ReactElement<unknown> => {
  return decorator ? decorator(element) : element
}

import { PhoneInput } from 'react-international-phone'
import styled from 'styled-components'

export const Container = styled.label<{
  $fullWidth?: boolean
}>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  ${({ $fullWidth }) => !$fullWidth && 'max-width: 588px;'}
`

export const LabelWrapper = styled.div`
  display: flex;
  gap: 8px;
`

export const ReactInternationalPhoneInput = styled(PhoneInput)<{
  $disabled?: boolean
  $hasError?: boolean
  $variant?: string
}>`
  && {
    width: 100%;
    display: flex;
    background-color: ${({ $disabled, $variant, theme }) =>
      $disabled
        ? theme.color.background.disabled
        : $variant === 'secondary'
          ? theme.color.background.input.secondary
          : theme.color.background.input.default};

    input {
      box-sizing: border-box;
      height: 48px;
      border: 1px solid
        ${({ $hasError, theme }) =>
          $hasError
            ? theme.color.border.danger
            : theme.color.border.input.default};
      border-radius: 0;
      font-size: 16px;
      flex: 1;
      padding: 16px 10px;
      background: none;
    }

    .react-international-phone-country-selector button {
      height: 48px;
      border: 1px solid
        ${({ $hasError, theme }) =>
          $hasError
            ? theme.color.border.danger
            : theme.color.border.input.default};
      border-radius: 0;
      width: 96px;
      background: none;

      li span {
        font-size: 16px;
      }
    }

    .react-international-phone-country-selector-button__dropdown-arrow {
      border: none;
      font-weight: normal;
      font-size: 14px;
      margin: 0 0 0 6px;
      display: flex;
      transform: none;

      &::before {
        font-family: 'icomoon' !important;
        content: '\\e909';
        color: ${({ $disabled, theme }) =>
          $disabled ? theme.color.icon.disabled : theme.color.icon.default};
      }
    }
  }
`

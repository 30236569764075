import classNames from 'classnames'
import React from 'react'

import { Wrapper, Content } from './styles'

export type Props = {
  children: number
  solid?: boolean
  small?: boolean
  onDarkBackground?: boolean
  className?: string
}

const StepNumber = ({
  children,
  onDarkBackground = false,
  solid = false,
  small = false,
  className,
}: Props) => (
  <Wrapper
    $onDarkBackground={onDarkBackground}
    $solid={solid}
    $small={small}
    className={classNames('amp-step-number', className)}
  >
    <Content>{children}</Content>
  </Wrapper>
)

export default StepNumber
